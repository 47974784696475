enum WebhookEventType {
  MINT = "MINT",
  TRANSFER = "TRANSFER",
  UPDATE = "UPDATE",
}
type WebhookEvent = { name: string, type: WebhookEventType };

export default WebhookEvent;

export { WebhookEventType };
