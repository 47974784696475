import { Chip, Stack, TableCell, TableRow } from "@mui/material";
import WebhookConfiguration from "~types/webhook/WebhookConfiguration";

interface WebhookEntryProps {
  webhook: WebhookConfiguration;
  onSelect: (webhook: WebhookConfiguration) => void;
}

const WebhookEntry = ({ webhook, onSelect }: WebhookEntryProps) => {
  const { events } = webhook;
  return (
    <TableRow
      sx={{ cursor: "pointer", "&:last-child td, &:last-child th": { border: 0 } }}
      onClick={() => onSelect(webhook)}
    >
      <TableCell>{webhook.endpoint}</TableCell>
      <TableCell>{webhook.description}</TableCell>
      <TableCell>
        <Stack gap={1} direction={"row"}>
          {events.map((event) => (
            <Chip
              key={`webhook-entry-${webhook._id}-event-${event}`}
              label={event.toLowerCase()}
              color="primary"
              size="small"
              sx={{ minWidth: "60px" }}
              variant="outlined"
            />
          ))}
        </Stack>
      </TableCell>

      <TableCell>{webhook.createdAt}</TableCell>
      <TableCell>{webhook.lastUsed}</TableCell>
    </TableRow>
  );
};

export default WebhookEntry;
