import apikeysSlice from "~features/apikeys/apikeys.slice";
import authSlice from "~features/auth/auth.slice";
import notificationsSlice from "./notifications/notifications.slice";
import userInvitationSlice from "~features/user-invitation/user-invitation.slice";
import webhookSlice from "~features/webhook/webhook.slice";

const reducers = {
  apikeys: apikeysSlice,
  auth: authSlice,
  notifications: notificationsSlice,
  userInvitation: userInvitationSlice,
  webhook: webhookSlice
};

export default reducers;
