import { all } from "redux-saga/effects";

import apikeySaga from "./apikeys/apikeys.sagas";
import authSaga from "./auth/auth.sagas";
import userInvitationSaga from "./user-invitation/user-invitation.sagas";
import webhookSaga from "./webhook/webhook.sagas";

function* rootSaga() {
  yield all([apikeySaga(), authSaga(), userInvitationSaga(), webhookSaga()]);
}

export default rootSaga;
