import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { selectWebhooks } from "~features/webhook/webhook.selectors";
import useAppSelector from "~hooks/useAppSelector";
import WebhookConfiguration from "~types/webhook/WebhookConfiguration";
import WebhookDetailsModal from "../WebhookDetailsModal/WebhookDetailsModal";
import WebhookEntry from "./components/WebhookEntry";

const ApiKeysTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWebhook, setSelectedWebhook] = useState<WebhookConfiguration | undefined>(undefined);
  const webhooks = useAppSelector(selectWebhooks);

  const onSelectItem = (webhook: WebhookConfiguration) => {
    setSelectedWebhook(webhook);
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    setSelectedWebhook(undefined);
    setIsModalOpen(false);
  };

  return (
    <>
      <TableContainer>
        <Table
          sx={{
            border: `1px solid #E0E0E0`,
            borderRadius: `10px`,
            borderCollapse: "separate",
            borderSpacing: 0,
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={5}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                  <Box>
                    <Typography variant="h5">Webhooks</Typography>
                    <Typography variant="body2">
                      These endpoints will allow you to stream events to your desired servers. Read the docs
                      here.
                    </Typography>
                  </Box>
                  <Box bgcolor={"white"}>
                    <Button
                      startIcon={<AddIcon />}
                      variant="contained"
                      color="inherit"
                      size={"small"}
                      sx={{ backgroundColor: "white" }}
                      onClick={() => setIsModalOpen(true)}
                    >
                      Create webhook
                    </Button>
                  </Box>
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Endpoint</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Events subscribed</TableCell>
              <TableCell>Last used</TableCell>
              <TableCell>Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {webhooks.map((webhook) => (
              <WebhookEntry key={`webhook-entry-${webhook._id}`} webhook={webhook} onSelect={onSelectItem} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <WebhookDetailsModal data={selectedWebhook} open={isModalOpen} onClose={() => onCloseModal()} />
    </>
  );
};

export default ApiKeysTable;
