const downloadDataAsFile = (fileName: string, data: string) => {
  const element = document.createElement("a");
  if (element) {
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(data));
    element.setAttribute("download", fileName);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}

export default downloadDataAsFile;