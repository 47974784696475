import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  deleteApiKey,
  deleteApiKeyFailure,
  deleteApiKeySuccess,
  createApiKey,
  createApiKeyFailure,
  createApiKeySuccess,
  getApiKeys,
  getApiKeysFailure,
  getApiKeysSuccess,
  regenerateApiKey,
  regenerateApiKeyFailure,
  regenerateApiKeySuccess,
} from "./apikeys.slice";
import { deleteProtectedAPI, fetchProtectedAPI, postProtectedAPI } from "../utils/api/api.sagas";
import ApiKeyConfiguration from "~types/apikey/ApiKeyConfiguration";
import { addNotification } from "~features/notifications/notifications.slice";
import downloadDataAsFile from "~utils/download-data-as-file";



function* getApiKeysSaga(): Iterator<any> {
  try {
    const response: Response = yield call([this, fetchProtectedAPI], "apikeys");
    const data = yield response.json();
    yield put(getApiKeysSuccess(data));
  } catch (error) {
    yield put(getApiKeysFailure(error));
  }
}

function* createApiKeySaga(action: PayloadAction<Partial<ApiKeyConfiguration>>): Iterator<any> {
  try {
    const response: Response = yield call(postProtectedAPI, "apikeys", action.payload);
    const data: ApiKeyConfiguration = yield response.json();
    const { secret, name } = data;

    if (data) {
      downloadDataAsFile(name, secret);

      delete data.secret;
      yield put(createApiKeySuccess(data));
      yield put(addNotification({ message: "API key created", severity: "success", duration: 3000 }));
    }
  } catch (error) {
    console.log({ error });
    yield put(addNotification({ message: "API key creation failed", severity: "error", duration: 3000 }));
    yield put(createApiKeyFailure(error));
  }
}

function* regenerateApiKeySaga(): Iterator<any> {
  try {
    const response: Response = yield call(() => {});
    const data = yield response.json();
    yield put(regenerateApiKeySuccess(data));
  } catch (error) {
    yield put(regenerateApiKeyFailure(error));
  }
}

function* deleteApiKeySaga(action: PayloadAction<string>): Iterator<any> {
  try {
    const response: Response = yield call(deleteProtectedAPI, `apikeys/${action.payload}`);
    const data = yield response.json();
    if ((data as any)?.deletedCount > 0) {
      yield put(deleteApiKeySuccess(action.payload));
      yield put(addNotification({ message: "API key deleted", severity: "info", duration: 3000 }));
    } else {
      throw new Error("Failed to delete API key");
    }
  } catch (error) {
    yield put(addNotification({ message: "API key deletion failed", severity: "error", duration: 3000 }));
    yield put(deleteApiKeyFailure(error));
  }
}

export default function* apikeySaga() {
  yield all([
    yield takeLatest(getApiKeys.type, getApiKeysSaga),
    yield takeEvery(createApiKey.type, createApiKeySaga),
    yield takeEvery(regenerateApiKey.type, regenerateApiKeySaga),
    yield takeEvery(deleteApiKey.type, deleteApiKeySaga),
  ]);
}

export { getApiKeysSaga, createApiKeySaga, regenerateApiKeySaga, deleteApiKeySaga };
