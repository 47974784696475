import { Box } from "@mui/material";
import { Outlet } from "react-router";
import MenuDrawer, { MenuDrawerVariant } from "./MenuDrawer";
import useAppSelector from "~hooks/useAppSelector";
import { selectIsLoggedIn } from "~features/auth/auth.selectors";

const Layout = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  return (
    <Box height={"100%"} overflow={"hidden"} padding={"24px 120px 24px 24px"}>
      {isLoggedIn && <MenuDrawer variant={MenuDrawerVariant.DASHBOARD} />}
      <Outlet />
    </Box>
  );
};

export default Layout;
