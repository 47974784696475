import V2TextInput from '~components/inputs/V2TextInput';
import type { LoginCredentials } from '~features/auth/auth.types';
import { isPasswordValid, isUsernameValid } from '~utils/auth/validators';

type LoginSignupFormProps = {
  loginState: LoginCredentials;
  setPassword: (value: string) => void;
  setUsername: (value: string) => void;
};

const LoginSignupForm = ({ loginState, setUsername, setPassword }: LoginSignupFormProps) => {
  return (
    <>
      <V2TextInput
        debounce={null}
        name="username"
        label="Username"
        variant="standard"
        value={loginState.username}
        validator={isUsernameValid}
        onUpdate={(val) => setUsername(val)}
      />

      <V2TextInput
        debounce={null}
        name="password"
        label="Password"
        type="password"
        variant="standard"
        value={loginState.password}
        validator={isPasswordValid}
        onUpdate={(val) => setPassword(val)}
      />
    </>
  );
};

export default LoginSignupForm;
