import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import WebhookConfiguration from "~types/webhook/WebhookConfiguration";

interface WebhookState {
  isLoading: boolean;
  error: string | null;
  webhooks: WebhookConfiguration[];
}

const initialState: WebhookState = {
  isLoading: false,
  error: null,
  webhooks: [],
};

const webhookSlice = createSlice({
  name: "webhook",
  initialState,
  reducers: {
    getWebhooks(state) {
      state.isLoading = true;
    },
    getWebhooksSuccess(state, action: PayloadAction<WebhookConfiguration[]>) {
      state.webhooks = action.payload;
      state.isLoading = false;
    },
    getWebhooksFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    createWebhook(state, action: PayloadAction<Partial<WebhookConfiguration>>) {
      state.isLoading = true;
    },
    createWebhookSuccess(state, action: PayloadAction<WebhookConfiguration>) {
      state.webhooks.push(action.payload);
      state.isLoading = false;
    },
    createWebhookFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    updateWebhook(state, action: PayloadAction<Partial<WebhookConfiguration>>) {
      state.isLoading = true;
    },
    updateWebhookSuccess(state, action: PayloadAction<WebhookConfiguration>) {
      const webhook = action.payload;
      const index = state.webhooks.findIndex((w) => w._id === webhook._id);
      if (index > -1) {
        state.webhooks[index] = webhook;
      } else {
        state.webhooks.push(webhook);
      }
      state.isLoading = false;
    },
    updateWebhookFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    deleteWebhook(state, action: PayloadAction<string>) {
      state.isLoading = true;
    },
    deleteWebhookSuccess(state, action: PayloadAction<string>) {
      const id = action.payload;
      const index = state.webhooks.findIndex((w) => w._id === id);
      if (index > -1) {
        state.webhooks.splice(index, 1);
      }
      state.isLoading = false;
    },
    deleteWebhookFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  createWebhook,
  createWebhookSuccess,
  createWebhookFailure,
  deleteWebhook,
  deleteWebhookSuccess,
  deleteWebhookFailure,
  getWebhooks,
  getWebhooksSuccess,
  getWebhooksFailure,
  updateWebhook,
  updateWebhookSuccess,
  updateWebhookFailure,
} = webhookSlice.actions;

export default webhookSlice.reducer;
