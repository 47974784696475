import { Box } from "@mui/material";
import { memo } from "react";
import type { RouteObject } from "react-router";
import { useRoutes } from "react-router-dom";
import ApiKeysConfiguration from "~components/ApiKeysConfiguration/ApiKeysConfiguration";
import AuthRequired from "~components/layout/AuthRequired/AuthRequired";
import Layout from "~components/layout/Layout";
import NoMatch from "~components/layout/NoMatch";
import Analytics from "../Analytics";
import Developers from "../Developers";
import Home from "../Home";
import Login from "../Login";
import Notifications from "~components/miscs/Notifications/Notifications";
import InvitationView from "~views/InvitationView/InvitationView";
import Routes from "~constants/routes";
import Signup from "~views/Signup/Signup";

const routes: RouteObject[] = [
  { path: Routes.INVITE, element: <InvitationView /> },
  { path: Routes.LOGIN, element: <Login /> },
  { path: Routes.SIGNUP, element: <Signup /> },
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      {
        path: Routes.DEVELPOERS,
        element: (
          <AuthRequired>
            <Developers />
          </AuthRequired>
        ),
        children: [{ path: Routes.API, element: <ApiKeysConfiguration /> }],
      },
      {
        path: Routes.ANALYTICS,
        element: (
          <AuthRequired>
            <Analytics />
          </AuthRequired>
        ),
      },
      { path: "*", element: <NoMatch /> },
    ],
  },
];

const App = () => {
  let element = useRoutes(routes);

  return (
    <Box className="App" sx={{ height: "100%" }}>
      <Notifications />
      {element}
    </Box>
  );
};

export default memo(App);
