import { LoadingButton } from "@mui/lab";
import { Box, Link, Stack } from "@mui/material";

import { useState } from "react";
import { selectIsLoading } from "~features/auth/auth.selectors";
import { login } from "~features/auth/auth.slice";

import useAppSelector from "~hooks/useAppSelector";
import { isPasswordValid, isUsernameValid } from "~utils/auth/validators";
import { CakeLogo } from "~icons/common";
import LoginSignupForm from "./LoginSignupForm";

import "./Login.scss";
import useAppDispatch from "~hooks/useAppDispatch";


const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isMultiFactorAuthView, setIsMultiFactorAuthView] = useState(false);
  const isLoading = useAppSelector(selectIsLoading);
  const loginState = { username, password };

  const canSubmit = isUsernameValid(loginState.username) && isPasswordValid(loginState.password);
  const dispatch = useAppDispatch();

  const onSubmit = () => {
    // TODO: Remove this when MultiFactorAuth is actually implemented via Auth0.
    if (process.env.REACT_APP_USE_2FA === "true" && !isMultiFactorAuthView) {
      return setIsMultiFactorAuthView(true);
    }

    dispatch(login(loginState));
  };
  return (
    <Stack className={"SOUS-CHEF__login"}>
      <Box className={"SOUS-CHEF__login__content"}>
        <Stack direction={"column"} alignItems={"center"} justifyContent={"center"} spacing={2}>
          <CakeLogo />
          <LoginSignupForm loginState={loginState} setPassword={setPassword} setUsername={setUsername} />
          <LoadingButton
            className={"SOUS-CHEF__login__content__button"}
            variant="contained"
            fullWidth
            size="large"
            loading={isLoading ? true : undefined}
            onClick={onSubmit}
            disabled={!canSubmit}
          >
            {"Sign In"}
          </LoadingButton>

          <Stack direction={"column"} alignItems={"center"} paddingTop={2} spacing={1}>
            <Link
              underline="none"
              fontWeight={"bold"}
              paddingTop={1}
              href="https://trycake.xyz"
              target="_blank"
            >
              Don&apos;t have an account?
            </Link>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

export default Login;
