import "~components/miscs/PageLoader/PageLoader.scss";

import { CircularProgress, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";

import logo from "~images/logo.png";

type PageLoaderProps = {
  title?: string;
  footer?: boolean;
};

const PageLoader = ({ title, footer }: PageLoaderProps) => (
  <Stack className={"CAKE__loader__container"} justifyContent="center" alignItems="center" gap={2}>
    <Box className={"CAKE__loader__image--container"}>
      <CircularProgress size={100} thickness={1} style={{ color: "white" }} disableShrink />
      <Box className={"CAKE__loader__image--logo"}>
        <img src={logo} alt="" />
      </Box>
    </Box>
    <Typography className={"CAKE__loader__title"}>{title}</Typography>
    <Box className={"CAKE__loader__footer"}>
      {footer && <Typography>Copyright ©2022 Cake App by Good Month Labs</Typography>}
    </Box>
  </Stack>
);

export default PageLoader;
