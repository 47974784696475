import "~components/auth/LoginSignupContainer/LoginSignupContainer.scss";

import { LoadingButton } from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";
import classNames from "classnames";
import type { PropsWithChildren, ReactNode } from "react";

import { loginWithSocial } from "~features/auth/auth.slice";
import { Connections } from "~features/auth/auth.types";
import useAppDispatch from "~hooks/useAppDispatch";
import { CakeLogo, GoogleIcon } from "~icons/common";

type LoginSignupContainerProps = PropsWithChildren<{
  canSubmit: boolean;
  className?: string;
  isLoading: boolean;
  hideActions?: boolean;
  onSubmit: () => void;
  submitText: string;
  slotBottom?: ReactNode;
}>;

const LoginSignupContainer = ({
  canSubmit,
  className = "",
  isLoading,
  hideActions,
  onSubmit,
  submitText,
  slotBottom,
  children,
}: LoginSignupContainerProps) => {
  // TODO: Change when we allow this.
  const canLoginWithSocial = false;
  const dispatch = useAppDispatch();

  const onLoginSocial = (connection: Connections) => {
    dispatch(loginWithSocial({ connection }));
  };

  return (
    <Stack className={classNames(className, "CAKE__login-container")}>
      <Box className={"CAKE__login-container__content"}>
        <Stack direction={"column"} alignItems={"center"} justifyContent={"center"} spacing={2}>
          <CakeLogo />

          {children}

          {!hideActions && (
            <>
              <LoadingButton
                className={"CAKE__login-container__content__button"}
                variant="contained"
                fullWidth
                size="large"
                loading={isLoading ? true : undefined}
                onClick={onSubmit}
                disabled={!canSubmit}
              >
                {submitText}
              </LoadingButton>

              <Stack direction={"column"} alignItems={"center"} paddingTop={2} spacing={1}>
                {canLoginWithSocial && (
                  <>
                    <Typography variant="body2">or sign in with</Typography>

                    <button
                      className={
                        "CAKE__login-container__content__icon-button CAKE__login-container__content__icon-button--google"
                      }
                      aria-label="google"
                      onClick={() => onLoginSocial(Connections.Google)}
                    >
                      <GoogleIcon />
                    </button>
                  </>
                )}

                {slotBottom}
              </Stack>
            </>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default LoginSignupContainer;
