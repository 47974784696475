import "./MenuDrawerFooter.scss";

import { Avatar, Box, IconButton, Typography } from "@mui/material";
import classNames from "classnames";
import { selectUserGravatar, selectUserNickname } from "~features/auth/auth.selectors";
import { logout } from "~features/auth/auth.slice";
import useAppSelector from "~hooks/useAppSelector";

import useAppDispatch from "~hooks/useAppDispatch";
import { LogoutIcon, SettingsIcon } from "~icons/common";

type MenuDrawerFooterProps = {
  small: boolean;
};

const MenuDrawerFooter = ({ small }: MenuDrawerFooterProps) => {
  const dispatch = useAppDispatch();
  const userAvatar = useAppSelector(selectUserGravatar);
  const userNickname = useAppSelector(selectUserNickname);

  return (
    <Box
      className={classNames({
        "CAKE__menu-drawer-footer": true,
        "CAKE__menu-drawer-footer--hidden": small,
      })}
    >
      <Box className={"CAKE__menu-drawer-footer__side-container"}>
        <Avatar className={"CAKE__menu-drawer-footer__avatar"} variant="rounded" src={userAvatar}></Avatar>
        <Typography
          variant={"body2"}
          className={classNames({
            "CAKE__menu-drawer-footer__element": true,
            "CAKE__menu-drawer-footer__name": true,
            "CAKE__menu-drawer-footer__element--hidden": small,
          })}
        >
          {userNickname}
        </Typography>
      </Box>
      <Box
        className={classNames({
          "CAKE__menu-drawer-footer__side-container": true,
          "CAKE__menu-drawer-footer__element": true,
          "CAKE__menu-drawer-footer__element--hidden": small,
        })}
      >
        <IconButton onClick={() => console.log("settings")} size="small">
          <SettingsIcon />
        </IconButton>
        <IconButton onClick={() => dispatch(logout())} size="small">
          <LogoutIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default MenuDrawerFooter;
