import { Stack } from "@mui/material";

import ApiKeysTable from "../ApiKeysTable/ApiKeysTable";
import WebhooksTable from "../WebhooksTable/WebhooksTable";
import { memo, useEffect } from "react";
import useAppDispatch from "~hooks/useAppDispatch";
import { getApiKeys } from "~features/apikeys/apikeys.slice";
import { getWebhooks } from "~features/webhook/webhook.slice";

const ApiKeysConfiguration = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getWebhooks());
    dispatch(getApiKeys());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack gap={12} pt={4}>
      <ApiKeysTable />
      <WebhooksTable />
    </Stack>
  );
};

export default memo(ApiKeysConfiguration);
