import { useEffect, useState } from 'react';

import useDebounce from '~utils/hooks/useDebounce';

export type UseInputProps = {
  debounce?: number;
  onUpdate: (value: string) => void | null;
  value: string;
  validator?: (value: string) => boolean;
};

const useInput = ({ debounce = 1000, onUpdate, validator, value }: UseInputProps) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [isValid, setIsValid] = useState(true);
  const [hasValidated, setHasValidated] = useState(false);

  useEffect(() => {
    if (validator && currentValue) {
      setIsValid(validator(currentValue));
      setHasValidated(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validator]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleUpdate = () => {
    if (validator) {
      if (currentValue) {
        setIsValid(validator(currentValue));
        setHasValidated(true);
      } else {
        setHasValidated(false);
      }
    }

    onUpdate?.(currentValue);
  };

  useDebounce(handleUpdate, [currentValue, validator], debounce);

  const onValueChange = (e) => {
    e.preventDefault();
    setCurrentValue(e.target.value);
  };

  return {
    currentValue,
    hasValidated,
    isValid,
    onValueChange,
  };
};

export default useInput;
