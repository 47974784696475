import type { Auth0Error } from 'auth0-js';

type UserType = {
  nickname: string;
  name: string;
  picture: string;
  updated_at: string;
  email: string;
  email_verified: boolean;
  sub: string;
};

enum Connections {
  Password = 'Username-Password-Authentication',
  Google = 'google-oauth2',
}

type LoginCredentials = {
  username: string;
  password: string;
};

type WebAuthClient = {
  loginWithPassword: ({ username, password }: LoginCredentials) => Promise<Auth0Error | any>;
  loginWithSocial: ({ connection }: { connection: Connections }) => void;
};

export { Connections };

export type { LoginCredentials, UserType, WebAuthClient };
