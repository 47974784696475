import { AnalyticsIcon, NotificationsIcon } from "~icons/menu";

const dashboardMenuItems = [
  {
    title: "Developers",
    linkPattern: "/developers/:feature",
    link: `/developers/api`,
    icon: <NotificationsIcon />,
  },
  {
    title: "Analytics",
    linkPattern: "/analytics",
    link: `/analytics`,
    icon: <AnalyticsIcon />,
  },
];

export { dashboardMenuItems };
