import "./MenuDrawerMenu.scss";

import { Box } from "@mui/material";

import MenuDrawerMenuButton from "../MenuDrawerMenuButton/MenuDrawerMenuButton";
import { useMatch } from "react-router-dom";

type MenuDrawerMenuProps = {
  small: boolean;
  items: Array<any>;
};
const MenuDrawerMenu = ({ items, small }: MenuDrawerMenuProps) => {
  return (
    <Box className={"CAKE__menu-drawer-menu"}>
      {items.map((item) => (
        <MenuDrawerMenuButton
          key={item.title}
          title={item.title}
          link={item.link}
          icon={item.icon}
          // eslint-disable-next-line react-hooks/rules-of-hooks
          isActive={Boolean(useMatch(item.linkPattern))}
          small={small}
        />
      ))}
    </Box>
  );
};

export default MenuDrawerMenu;
