import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import ApiKeyConfiguration from "~types/apikey/ApiKeyConfiguration";

interface ApiKeyState {
  isLoading: boolean;
  error: string | null;
  apiKeys: ApiKeyConfiguration[];
}

const initialState: ApiKeyState = {
  isLoading: false,
  error: null,
  apiKeys: [],
};

const apiKeysSlice = createSlice({
  name: "apiKeys",
  initialState,
  reducers: {
    getApiKeys(state) {
      state.isLoading = true;
    },
    getApiKeysSuccess(state, action: PayloadAction<ApiKeyConfiguration[]>) {
      state.apiKeys = action.payload;
      state.isLoading = false;
    },
    getApiKeysFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    createApiKey(state,action: PayloadAction<Partial<ApiKeyConfiguration>>) {
      state.isLoading = true;
    },
    createApiKeySuccess(state, action: PayloadAction<ApiKeyConfiguration>) {
      state.apiKeys.push(action.payload);
      state.isLoading = false;
    },
    createApiKeyFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    regenerateApiKey(state) {
      state.isLoading = true;
    },
    regenerateApiKeySuccess(state, action: PayloadAction<ApiKeyConfiguration>) {
      const apiKey = action.payload;
      const index = state.apiKeys.findIndex((w) => w._id === apiKey._id);
      if (index > -1) {
        state.apiKeys[index] = apiKey;
      }
      state.isLoading = false;
    },
    regenerateApiKeyFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    deleteApiKey(state, action: PayloadAction<string>) {
      state.isLoading = true;
    },
    deleteApiKeySuccess(state, action: PayloadAction<string>) {
      const id = action.payload;
      const index = state.apiKeys.findIndex((w) => w._id === id);
      if (index > -1) {
        state.apiKeys.splice(index, 1);
      }
      state.isLoading = false;
    },
    deleteApiKeyFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  getApiKeys,
  getApiKeysSuccess,
  getApiKeysFailure,
  createApiKey,
  createApiKeySuccess,
  createApiKeyFailure,
  regenerateApiKey,
  regenerateApiKeySuccess,
  regenerateApiKeyFailure,
  deleteApiKey,
  deleteApiKeySuccess,
  deleteApiKeyFailure,
} = apiKeysSlice.actions;

export default apiKeysSlice.reducer;
