import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { selectApiKeys } from "~features/apikeys/apikeys.selectors";
import useAppSelector from "~hooks/useAppSelector";
import ApiKeyConfiguration from "~types/apikey/ApiKeyConfiguration";
import ApiKeyCreationModal from "../ApiKeyCreationModal/ApiKeyCreationModal";
import PublicKeyEntry from "./components/PublicKeyEntry";
import useAppDispatch from "~hooks/useAppDispatch";
import { createApiKey, deleteApiKey } from "~features/apikeys/apikeys.slice";

const ApiKeysTable = () => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const apiKeyEntries: ApiKeyConfiguration[] = useAppSelector(selectApiKeys);

  useEffect(() => {
    setIsModalOpen(false);
  }, [apiKeyEntries]);

  const onRegeneratePublicKey = () => {};

  const onDeleteApiKey = (apiKey: ApiKeyConfiguration) => {
    dispatch(deleteApiKey(apiKey._id));
  };

  const onConfirmKeyCreation = (apikey: Partial<ApiKeyConfiguration>) => {
    dispatch(createApiKey(apikey));
  };

  return (
    <>
      <TableContainer>
        <Table
          sx={{
            border: `1px solid #E0E0E0`,
            borderRadius: `10px`,
            borderCollapse: "separate",
            borderSpacing: 0,
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={5}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                  <Box>
                    <Typography variant="h5">Standard keys</Typography>
                    <Typography variant="body2">
                      These keys will allow you to authenticate API requests. Learn more
                    </Typography>
                  </Box>
                  <Box bgcolor={"white"}>
                    <Button
                      startIcon={<AddIcon />}
                      variant="contained"
                      color="inherit"
                      size={"small"}
                      onClick={() => setIsModalOpen(true)}
                      sx={{ backgroundColor: "white" }}
                    >
                      Create api key
                    </Button>
                  </Box>
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Token</TableCell>
              <TableCell>Last used</TableCell>
              <TableCell>Created</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apiKeyEntries.map((apiKeyEntry: ApiKeyConfiguration) => (
              <PublicKeyEntry key={`api-key-entry-${apiKeyEntry._id}`} data={apiKeyEntry} onDelete={() => onDeleteApiKey(apiKeyEntry)} onRegenerate={onRegeneratePublicKey} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ApiKeyCreationModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={onConfirmKeyCreation}
      />
    </>
  );
};

export default ApiKeysTable;
