import WebhookIcon from "@mui/icons-material/Webhook";
import { Button, InputAdornment, Stack } from "@mui/material";

import { useEffect, useState } from "react";
import { createWebhook, deleteWebhook, updateWebhook } from "~features/webhook/webhook.slice";
import useAppDispatch from "~hooks/useAppDispatch";
import WebhookConfiguration from "~types/webhook/WebhookConfiguration";
import WebhookEvent, { WebhookEventType } from "~types/webhook/WebhookEvent";
import MultipleSelect, { MultipleSelectData } from "../inputs/MultipleSelect/MultipleSelect";
import V2TextInput from "../inputs/V2TextInput/V2TextInput";
import Modal from "../miscs/Modal/Modal";

interface WebhookDetailsModalProps {
  data?: WebhookConfiguration | null;
  open: boolean;
  onClose: () => void;
}

const eventsOptions: MultipleSelectData[] = [
  { name: "Mint Event", type: WebhookEventType.MINT },
  { name: "Transfer Event", type: WebhookEventType.TRANSFER },
  { name: "Update Event", type: WebhookEventType.UPDATE },
];

const WebhookDetailsModal = ({ data, open, onClose }: WebhookDetailsModalProps) => {
  const [selectedEvents, setSelectedEvents] = useState<MultipleSelectData[]>([]);
  const [description, setDescription] = useState<string>("");
  const [webhookURL, setWebhookURL] = useState<string>("");

  const dispatch = useAppDispatch();

  const isEditMode = Boolean(data);

  useEffect(() => {
    if (data) {
      setSelectedEvents(eventsOptions.filter((e) => (data.events || []).includes(e.type)));
      setDescription(data.description);
      setWebhookURL(data.endpoint);
    }
  }, [data]);

  const onSave = () => {
    if (!isEditMode) {
      dispatch(
        createWebhook({ description, endpoint: webhookURL, events: selectedEvents.map((e) => e.type) })
      );
    } else {
      dispatch(
        updateWebhook({
          ...data,
          description,
          uendpointrl: webhookURL,
          events: selectedEvents.map((e) => e.type),
        })
      );
    }

    onCloseModal();
  };

  const onDelete = () => {
    dispatch(deleteWebhook(data?._id));
    onCloseModal();
  };

  const onCloseModal = () => {
    setSelectedEvents([]);
    setDescription("");
    setWebhookURL("");
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      title={"Create a webhook"}
      footer={
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems={"center"}
          width={"100%"}
          pb={1.8}
          gap={2}
        >
          {isEditMode && (
            <Button variant="contained" color="error" onClick={() => onDelete()}>
              Delete
            </Button>
          )}
          <Button variant="contained" onClick={() => onSave()}>
            Save
          </Button>
        </Stack>
      }
    >
      <Stack gap={4} pt={2}>
        <V2TextInput
          label={"Webhook URL"}
          value={webhookURL}
          onUpdate={setWebhookURL}
          inputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <WebhookIcon />
              </InputAdornment>
            ),
          }}
        />
        <V2TextInput
          label={"Description of service"}
          value={description}
          multiline
          onUpdate={setDescription}
          rows={3}
        />
        <MultipleSelect
          items={eventsOptions}
          selectedItems={selectedEvents}
          onChange={(value: WebhookEvent[]) => setSelectedEvents(value)}
        />
      </Stack>
    </Modal>
  );
};

export default WebhookDetailsModal;
