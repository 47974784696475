import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";

interface MultipleSelectData {
  name: string;
  type: string;
}

interface MultipleSelectProps {
  items: MultipleSelectData[];
  selectedItems: MultipleSelectData[];
  onChange: (items: MultipleSelectData[]) => void;
}

const MultipleSelect = ({ items, selectedItems = [], onChange }: MultipleSelectProps) => {
  const onChangeSelectedItems = (e: SelectChangeEvent<string[]>) => {
    const events: MultipleSelectData[] = items.filter((item) => e.target.value.includes(item.type));

    onChange(events);
  };

  return (
    <div>
      <FormControl sx={{ width: 300 }}>
        <InputLabel id="demo-multiple-name-label">Events</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={selectedItems.map((item) => item.type)}
          onChange={onChangeSelectedItems}
          input={<OutlinedInput label="Name" />}
        >
          {items.map((item: { name: string; type: string }, idx: number) => (
            <MenuItem key={`${item.name}-${idx}`} value={item.type}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultipleSelect;
export type { MultipleSelectData };
