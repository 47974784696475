import { Auth0Client } from "@auth0/auth0-spa-js";
import type { AuthOptions, WebAuth } from "auth0-js";
import auth0Client from "auth0-js";

import type { LoginCredentials, WebAuthClient } from "~features/auth/auth.types";
import { Connections } from "~features/auth/auth.types";

const credentials: AuthOptions = {
  domain: (process.env.REACT_APP_AUTH0_DOMAIN || "").toString(),
  clientID: (process.env.REACT_APP_AUTH0_CLIENT_ID || "").toString(),
  redirectUri: (process.env.REACT_APP_AUTH0_CALLBACK_URL || "").toString(),
  audience: (process.env.REACT_APP_AUTH0_AUDIENCE || "").toString(),
};

const createAuthClient = (): Auth0Client & WebAuthClient => {
  const baseClient: WebAuth = new auth0Client.WebAuth(credentials);

  const spaClient: Auth0Client = new Auth0Client({
    domain: credentials.domain,
    client_id: credentials.clientID,
    audience: credentials.audience,
    redirect_uri: credentials.redirectUri,
  });

  const loginWithPassword = ({ username, password }: LoginCredentials) => {
    return new Promise((resolve, reject) => {
      baseClient.login(
        { username, password, realm: Connections.Password, responseType: "token id_token" },
        (error, result) => {
          if (error) {
            reject(error);
          }

          resolve(result);
        }
      );
    });
  };

  const loginWithSocial = async ({ connection }: { connection: Connections }) => {
    baseClient.authorize({ connection, prompt: "login", responseType: "token id_token" });
  };

  Object.assign(spaClient, { loginWithPassword, loginWithSocial });

  return spaClient as Auth0Client & WebAuthClient;
};

const authClient = createAuthClient();

const getAuthClient = () => {
  return authClient;
};

export { getAuthClient };
