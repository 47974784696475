import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { Outlet } from "react-router";

const Developers = () => {
  return (
    <Box>
      <Typography variant="h4">Partners</Typography>
      <Outlet />
    </Box>
  );
};

export default memo(Developers);
