import { PropsWithChildren, memo } from "react";
import { Navigate } from "react-router";
import { selectIsLoading, selectIsLoggedIn } from "~features/auth/auth.selectors";
import useAppSelector from "~hooks/useAppSelector";

type AuthRequireProps = PropsWithChildren<{}>;

const AuthRequired = ({ children }: AuthRequireProps) => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const isLoading = useAppSelector(selectIsLoading);

  if (isLoggedIn) return <>{children}</>;
  if (isLoading) return null;
  return <Navigate to="/login" replace />;
};

export default memo(AuthRequired);
