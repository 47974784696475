import { Stack, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import V2TextInput from "~components/inputs/V2TextInput/V2TextInput";
import Modal from "../miscs/Modal/Modal";
import ApiKeyConfiguration from "~types/apikey/ApiKeyConfiguration";

interface ApiKeyCreationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (apikey: Partial<ApiKeyConfiguration>) => void;
}

const ApiKeyCreationModal = ({ open, onClose, onConfirm }: ApiKeyCreationModalProps) => {
  const [name, setName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!open) {
      setName("");
      setIsLoading(false);
    }
  }, [open]);

  const onConfirmClick = () => {
    setIsLoading(true);
    onConfirm({ name });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={"Create new Secret API KEY"}
      footer={
        <Stack direction="row" justifyContent="flex-end" alignItems={"center"} width={"100%"} pb={1.8}>
          <LoadingButton
            color="success"
            variant="contained"
            onClick={() => onConfirmClick()}
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SendIcon />}
          >
            Confirm
          </LoadingButton>
        </Stack>
      }
    >
      <Stack gap={4} pt={2}>
        <Typography variant="body2">
          When you create new API KEY, you will be prompted to download a file containing your new API KEY. It
          will be one time action. Please keep it safe as you won't be able to download it again.
        </Typography>
        <V2TextInput label={"Name"} value={name} onUpdate={setName} />
      </Stack>
    </Modal>
  );
};

export default ApiKeyCreationModal;
