import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  createWebhook,
  createWebhookFailure,
  createWebhookSuccess,
  deleteWebhook,
  deleteWebhookFailure,
  deleteWebhookSuccess,
  getWebhooks,
  getWebhooksFailure,
  getWebhooksSuccess,
  updateWebhook,
  updateWebhookFailure,
  updateWebhookSuccess,
} from "./webhook.slice";
import {
  deleteProtectedAPI,
  fetchProtectedAPI,
  postProtectedAPI,
  putProtectedAPI,
} from "../utils/api/api.sagas";
import { PayloadAction } from "@reduxjs/toolkit";
import WebhookConfiguration from "~types/webhook/WebhookConfiguration";
import { addNotification } from "~features/notifications/notifications.slice";

function* getWebhooksSaga(): Iterator<any> {
  try {
    const response: Response = yield call(fetchProtectedAPI, "webhooks");
    const data = yield response.json();
    yield put(getWebhooksSuccess(data));
  } catch (error) {
    yield put(getWebhooksFailure(error));
  }
}

function* createWebhookSaga(action: PayloadAction<Partial<WebhookConfiguration>>): Iterator<any> {
  try {
    const response: Response = yield call(postProtectedAPI, "webhooks", action.payload);
    const data = yield response.json();
    yield put(createWebhookSuccess(data));
    yield put(addNotification({ message: "webhook created", severity: "success", duration: 3000 }));
  } catch (error) {
    yield put(addNotification({ message: "webhook creation failed", severity: "error", duration: 3000 }));
    yield put(createWebhookFailure(error));
  }
}

function* updateWebhookSaga(action: PayloadAction<WebhookConfiguration>): Iterator<any> {
  try {
    delete action.payload.lastUsed;
    delete action.payload.createdAt;

    const response: Response = yield call(putProtectedAPI, `webhooks/${action.payload._id}`, action.payload);
    const data = yield response.json();
    yield put(updateWebhookSuccess(data));
    yield put(addNotification({ message: "webhook updated", severity: "success", duration: 3000 }));
  } catch (error) {
    yield put(updateWebhookFailure(error));
    yield put(addNotification({ message: "webhook update failed", severity: "error", duration: 3000 }));
  }
}

function* deleteWebhookSaga(action: PayloadAction<string>): Iterator<any> {
  try {
    const response: Response = yield call(deleteProtectedAPI, `webhooks/${action.payload}`);
    const data = yield response.json();
    if ((data as any).acknowledged) {
      yield put(deleteWebhookSuccess(action.payload));
      yield put(addNotification({ message: "webhook deleted", severity: "success", duration: 3000 }));
    }
  } catch (error) {
    yield put(addNotification({ message: "webhook deletion failed", severity: "error", duration: 3000 }));
    yield put(deleteWebhookFailure(error));
  }
}

export default function* webhookSaga(): Iterator<any> {
  yield all([
    yield takeLatest(getWebhooks.type, getWebhooksSaga),
    yield takeLatest(updateWebhook.type, updateWebhookSaga),
    yield takeEvery(createWebhook.type, createWebhookSaga),
    yield takeEvery(deleteWebhook.type, deleteWebhookSaga),
  ]);
}

export { getWebhooks, updateWebhook };
