import { Stack, TableCell, TableRow, Typography } from "@mui/material";
import CopyButton from "~components/buttons/CopyButton/CopyButton";
import ThreeDotsButton from "~components/buttons/ThreeDotsButton/ThreeDotsButton";
import ApiKeyConfiguration from "~types/apikey/ApiKeyConfiguration";

type PublicKeyEntryProps = {
  data: ApiKeyConfiguration;
  onDelete: () => void;
  onRegenerate: () => void;
};
const PublicKeyEntry = ({ data, onDelete, onRegenerate }: PublicKeyEntryProps) => {
  const { name, apiKey, lastUsed, createdAt } = data;

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell sx={{ maxWidth: "120px" }}>
        <Stack
          direction={"row"}
          maxWidth={"fit-content"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={1}
        >
          <Typography variant="body2">{name}</Typography>
          <CopyButton text={apiKey} disabled={!Boolean(apiKey)} />
        </Stack>
      </TableCell>
      <TableCell sx={{ maxWidth: "200px", wordWrap: "break-word" }}>
        <Typography
          sx={{
            lineBreak: "anywhere",
            WebkitLineClamp: 5,
            WebkitBoxOrient: "vertical",
            display: "-webkit-box",
            overflow: "hidden",
            lineHeight: "1.2em",
          }}
        >
          {apiKey}
        </Typography>
      </TableCell>
      <TableCell>{lastUsed}</TableCell>
      <TableCell>{createdAt}</TableCell>
      <TableCell sx={{ maxWidth: "20px", textAlign: "center" }}>
        <ThreeDotsButton
          options={[
            { label: "Delete", onClick: onDelete },
            { label: "Regenerate", onClick: onRegenerate },
          ]}
        />
      </TableCell>
    </TableRow>
  );
};

export default PublicKeyEntry;
