import { call, select } from "redux-saga/effects";

import { getTokenSaga } from "~features/auth/auth.sagas";
import { selectSessionId, selectUserEmail, selectUserId } from "~features/auth/auth.selectors";
import { commonHeaders } from "~features/utils/api/api.consts";
import { getBatterRequestPath } from "~features/utils/api/api.helpers";

function* fetchProtectedAPI(
  path: string,
  params?: { [key: string]: Array<string | number> }
): Iterator<any, any> {
  const token: string = yield call(getTokenSaga);
  const userEmail: string = yield select(selectUserEmail);
  const userId: string = yield select(selectUserId);
  const sid: string = yield select(selectSessionId);

  const requestURL = getBatterRequestPath(path, params);
  try {
    const res: any = yield call([this, fetch], requestURL, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        ...commonHeaders,
        "sous-chef-authorization": `Bearer ${token}`,
        user_id: userId,
        email: userEmail,
        sid,
      },
    });
    return res;
  } catch (e) {
    console.log({ e });
  }
}

function* deleteProtectedAPI(path: string): Iterator<any, any> {
  const token: string = yield call(getTokenSaga);
  const userEmail: string = yield select(selectUserEmail);
  const userId: string = yield select(selectUserId);
  const sid: string = yield select(selectSessionId);

  const requestURL = getBatterRequestPath(path);

  try {
    const res: any = yield call<(info: RequestInfo, config: any) => any>(fetch, requestURL, {
      method: "DELETE",
      mode: "cors",
      cache: "no-cache",
      headers: {
        ...commonHeaders,
        "sous-chef-authorization": `Bearer ${token}`,
        user_id: userId,
        email: userEmail,
        sid,
      },
    });
    return res;
  } catch (e) {
    console.log({ e });
  }
}

function* postProtectedAPI(path: string, body: object): Iterator<any, any> {
  const token: string = yield call(getTokenSaga);
  const userEmail: string = yield select(selectUserEmail);
  const userId: string = yield select(selectUserId);
  const sid: string = yield select(selectSessionId);

  const requestURL = getBatterRequestPath(path);

  let payload = "";
  try {
    payload = JSON.stringify(body);
  } catch (e) {
    console.log("json parsing error");
    return;
  }

  //TODO: add payload
  try {
    const res: any = yield call<(info: RequestInfo, config: any) => any>(fetch, requestURL, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        ...commonHeaders,
        "sous-chef-authorization": `Bearer ${token}`,
        user_id: userId,
        email: userEmail,
        sid,
      },
      body: payload,
    });
    return res;
  } catch (e) {
    console.log({ e });
  }
}

function* putProtectedAPI(path: string, body: object | string): Iterator<any, any> {
  const token: string = yield call(getTokenSaga);
  const userEmail: string = yield select(selectUserEmail);
  const userId: string = yield select(selectUserId);
  const sid: string = yield select(selectSessionId);

  const requestURL = getBatterRequestPath(path);

  let payload = "";
  try {
    payload = JSON.stringify(body);
  } catch (e) {
    console.log("json parsing error");
    return;
  }

  try {
    const res: any = yield call<(info: RequestInfo, config: any) => any>(fetch, requestURL, {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      headers: {
        ...commonHeaders,
        "sous-chef-authorization": `Bearer ${token}`,
        user_id: userId,
        email: userEmail,
        sid,
      },
      body: payload,
    });
    return res;
  } catch (e) {
    console.log({ e });
  }
}

function* postAPI(path: string, body: object | string): Iterator<any, any> {
  const userEmail: string = yield select(selectUserEmail);
  const userId: string = yield select(selectUserId);
  const sid: string = yield select(selectSessionId);

  const requestURL = getBatterRequestPath(path);

  let payload = "";
  try {
    payload = JSON.stringify(body);
  } catch (e) {
    console.log("json parsing error");
    return;
  }

  const res: Response = yield call<(info: RequestInfo, config: any) => Promise<Response>>(fetch, requestURL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      ...commonHeaders,
      user_id: userId,
      email: userEmail,
      sid,
    },
    body: payload,
  });

  return res;
}

export { deleteProtectedAPI, fetchProtectedAPI, postProtectedAPI, putProtectedAPI, postAPI };
