import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import type { IconButtonProps } from '@mui/material';
import { IconButton, Tooltip } from "@mui/material";
import { memo, useEffect, useState } from "react";

type CopyButtonProps = IconButtonProps & {
  text: string;
};

const CopyButton = ({ text, ...props }: CopyButtonProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useEffect(() => {
    if (isTooltipOpen) {
      setInterval(() => setIsTooltipOpen(false), 2000);
    }
  }, [isTooltipOpen]);

  const onCopy = () => {
    navigator.clipboard.writeText(text);
    setIsTooltipOpen(true);
  };

  return (
    <Tooltip title="Copied!" arrow placement="bottom" open={isTooltipOpen}>
      <IconButton size="small" {...props} onClick={() => onCopy()}>
        <ContentCopyIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default memo(CopyButton);
