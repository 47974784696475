/* eslint-disable, @typescript-eslint/no-empty-function */

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { Connections, LoginCredentials, UserType } from "~features/auth/auth.types";

interface AuthState {
  isLoading: boolean;
  isLoggedIn: boolean;
  user?: UserType;
  sid?: string;
}

const initialState: AuthState = {
  isLoading: true,
  isLoggedIn: false,
  user: undefined,
  sid: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action: PayloadAction<LoginCredentials>) {
      state.isLoading = true;
    },
    loginWithSocial(state, action: PayloadAction<{ connection: Connections }>) {
      state.isLoading = true;
    },
    logout(state) {
      state.user = undefined;
    },
    signup(state, action: PayloadAction<string>) {},
    setIsLoggedIn(state, action: PayloadAction<boolean>) {
      state.isLoggedIn = action.payload;
      state.isLoading = false;
    },
    setUser(state, action: PayloadAction<any>) {
      state.user = action.payload;
    },
    setSessionId(state, action: PayloadAction<string>) {
      state.sid = action.payload;
    },
  },
});

export const { login, loginWithSocial, logout, setIsLoggedIn, signup, setUser, setSessionId } =
  authSlice.actions;
export default authSlice.reducer;
